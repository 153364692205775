/* global $ */
import { Swiper, Navigation, Pagination, Autoplay } from 'swiper/dist/js/swiper.esm.js'
import './swiper.css'

Swiper.use([Navigation, Pagination, Autoplay])

function init () {
  $(document).ready(function () {
    // initialize swiper when document ready
    const swiper = new Swiper('.swiper-container', {
      autoHeight: false,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })
    swiper.init()
  })
}

export default {
  init
}
