/**
 * File skip-link-focus-fix.js.
 *
 * Helps with accessibility for keyboard only users.
 *
 * Learn more: https://git.io/vWdr2
 *
 *
 */
(function () {
  var isIe = /(trident|msie)/i.test(navigator.userAgent)

  if (isIe && document.getElementById && window.addEventListener) {
    window.addEventListener('hashchange', function () {
      // eslint-disable-next-line no-undef
      var id = location.hash.substring(1)

      var element

      if (!(/^[A-z0-9_-]+$/.test(id))) {
        return
      }

      element = document.getElementById(id)

      if (element) {
        if (!(/^(?:a|select|input|button|textarea)$/i.test(element.tagName))) {
          element.tabIndex = -1
        }

        element.focus()
      }
    }, false)
  }
})()
