/* global $ */
import Headroom from 'headroom.js'

import './jquery.slicknav.js'

import './slicknav.css'

function startSlickNav () {
  console.log('Making slicknav...')
  // create a deferred object
  let deferred = $.Deferred()
  $('#menu').slicknav()
  // once the taks is completed mark the promise as resolved
  deferred.resolve(1)
  // return the promise object
  return deferred.promise()
}

function setHeadroom () { // create a deferred object
  console.log('Making headroom...')
  let deferred = $.Deferred()
  const navMobile = document.querySelector('.slicknav_menu')
  if (navMobile) {
    console.log('test')
    const headroom = new Headroom(navMobile, {
      offset: 200,
      tolerance: 5,
      classes: {
        // when element is initialised
        initial: 'headroom--mobile',
        // when scrolling up
        pinned: 'headroom--pinned--mobile',
        // when scrolling down
        unpinned: 'headroom--unpinned--mobile'
      }
    })
    headroom.init()
  }
  // once the taks is completed mark the promise as resolved
  deferred.resolve(2)
  return deferred.promise()
}

let promise1 = startSlickNav()
let promise2 = setHeadroom()

function init () {
  $.when(promise1, promise2).then(function (a, b) {})
}

export default {
  init
}
