/* Main super component */

/* Styles */
import '../assets/css/main.css'

/* Main scripts */
import './main-scripts'

/* Components */
import navMobile from '../components/nav-mobile'
import header from '../components/header'
import hero from '../components/hero'

const appStart = () => {
  navMobile.init()
  header.init()
  hero.init()
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', appStart)
} else {
  appStart()
}
